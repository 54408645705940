import { Sidebar, Menu, MenuItem, SubMenu,useProSidebar } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Link, Route, Routes } from "react-router-dom";
import { Dashboard, Home, Transactions,FrontendApplication, Register, SSO, SsoEmail } from "./home";
import './App.css';


const App = () => {
  const { collapseSidebar } = useProSidebar();

  return (
    <div style={{ display: "flex", height:"100%" }}>
      <Sidebar className="app">
        <Menu>
          <MenuItem
            component={<Link to="dashboard" className="link" />}
            className="menu1"
            icon={<MenuRoundedIcon  onClick={() => {
              collapseSidebar();
            }} />}
          >
            <h2>QUICKPAY</h2>
          </MenuItem>
          <MenuItem
            component={<Link to="/" className="link" />}
            icon={<GridViewRoundedIcon />}
          >
            Home
          </MenuItem>
          <SubMenu label="Frontend Application" icon={<BarChartRoundedIcon />}>
            <MenuItem 
             component={<Link to="frontend" className="link" />}
             icon={<TimelineRoundedIcon />}
             >
             Frontend Application 
            </MenuItem>
          </SubMenu>
          <SubMenu label="Fill Registration Form" icon={<WalletRoundedIcon />}>
            <MenuItem 
            component={<Link to="register" className="link" />}
            icon={<AccountBalanceRoundedIcon />}>
            Registration Form
            </MenuItem>
          </SubMenu>
          
          <SubMenu label="SSO" icon={<SettingsApplicationsRoundedIcon />}>
            <MenuItem  component={<Link to="sso" className="link" />} icon={<AccountCircleRoundedIcon />}> Sso </MenuItem>
            <MenuItem   component={<Link to="sso-email" className="link" />} icon={<NotificationsRoundedIcon />}>
              Varification Email
            </MenuItem>
          </SubMenu>
          {/* <MenuItem icon={<LogoutRoundedIcon />}> Logout </MenuItem> */}
        </Menu>
      </Sidebar>
      <section>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="frontend" element={<FrontendApplication />} />
          <Route path="register" element={<Register />} />
          <Route path="sso" element={<SSO />} />
          <Route path="sso-email" element={<SsoEmail />} />


        </Routes>
      </section>
    </div>
  );
};
export default App;