// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #165a72;
overflow-y: hidden;
}
.app {
  background: #a8d5e5;
  
}
h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,0KAA0K;EAC1K,mCAAmC;EACnC,kCAAkC;EAClC,aAAa;EACb,mBAAmB;AACrB,kBAAkB;AAClB;AACA;EACE,mBAAmB;;AAErB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Figtree\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Figtree\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100vh;\n  background: #165a72;\noverflow-y: hidden;\n}\n.app {\n  background: #a8d5e5;\n  \n}\nh1 {\n  color: #165a72;\n  margin: 50px auto;\n  font-size: 40px;\n}\n.menu1 {\n  margin-bottom: 40px;\n  margin-top: 20px;\n}\nh2 {\n  color: #165a72;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
