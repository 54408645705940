import { Routes, Route, Link } from "react-router-dom";
import home from '../src/assets/images/home-section.png';
import frontend from '../src/assets/images/frontend.png';
import register from '../src/assets/images/register.png';
import Sso from '../src/assets/images/sso.png';
import emailSso from '../src/assets/images/email.png';


export const Home = () => {
  return (
    <>
    
       <img
    src={home}
    alt="Figma Design"
    style={{ width: '100%', height: '100vh',}}
    />

    </>
  );
};

export const Dashboard = () => {
  return (
    <>
    
       <img
    src={home}
    alt="Figma Design"
    style={{ width: '100%', height: 'auto',height: '100vh' }}
    />

    </>
  );
};
export const FrontendApplication = () => {
    return (
      <>
         <img
      src={frontend}
      alt="Figma Design"
      style={{ width: '100%', height: 'auto',height: '100vh' }}
      />
  
      </>
    );
  };
  export const Register = () => {
    return (
      <>
         <img
      src={register}
      alt="Figma Design"
      style={{ width: '100%',height: '100vh' }}
      />
  
      </>
    );
  };
  export const SSO = () => {
    return (
      <>
         <img
      src={Sso}
      alt="Figma Design"
      style={{ width: '100%',height: '100vh' }}
      />
  
      </>
    );
  };
  export const SsoEmail = () => {
    return (
      <>
         <img
      src={emailSso}
      alt="Figma Design"
      style={{ width: '100%',height: '100vh' }}
      />
  
      </>
    );
  };

export const Transactions = () => {
  return (
    <>
      <h1 className="header">KEEP TRACK OF YOUR SPENDINGS</h1>
      <h3>Seamless Transactions</h3>
      <p>Lorem ipsum dolor sit amet...</p>
    </>
  );
};